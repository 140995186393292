import React from "react";

export default function Nav() {
  return (
    <nav
      className="navbar navbar-light navbar-expand-lg sticky-top shadow-sm transparency border-bottom border-light"
      id="transmenu"
      style={{
        paddingLeft: "0px",
        paddingTop: "0px",
        height: "75px",
        background: "#ffffff",
        zIndex: 20
      }}
    >
      <div className="container">
        <a
          className="navbar-brand text-success"
          href="#header"
          style={{ paddingTop: "8px" }}
        >
          &nbsp;
          <img
            alt="logo"
            src="assets/img/5%20am%20Club%20(1).png"
            style={{ width: "165px", marginTop: "8px", height: "44px" }}
          />
        </a>
        <button
          data-toggle="collapse"
          className="navbar-toggler collapsed"
          data-target="#navcol-1"
          style={{ marginTop: "-9px", background: "rgba(255,255,255,0)" }}
        >
          <span
            style={{
              background: "rgb(1,149,255)",
              width: "25px",
              height: "2px"
            }}
          />
          <span style={{ background: "rgb(0,149,255)" }} />
          <span style={{ background: "rgb(0,149,255)", marginTop: "5px" }} />
        </button>
        <div
          className="collapse navbar-collapse"
          id="navcol-1"
          style={{
            background: "rgba(255,255,255,0.81)",
            zIndex: 10,
            marginLeft: "167px"
          }}
        >
          <ul
            className="nav navbar-nav ml-auto"
            style={{ background: "rgb(255,255,255)", marginLeft: "0px" }}
          >
            <li className="nav-item" style={{ marginLeft: "30px" }}>
              {/* <a
                className="nav-link"
                href="#"
                style={{ color: "rgb(0,149,255)" }}
              >
                Whatsapp group
              </a> */}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
