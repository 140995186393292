import React from "react";

export default function row(number, a, b, c) {
  var A = JSON.parse(a);
  var B = JSON.parse(b);
  var C = JSON.parse(c);
  return (
    <div className="container">
      <div className="intro" style={{ textAlign: "left" }}>
        <h2
          className="text-center"
          style={{ textAlign: "left", paddingTop: "0px" }}
        >
          Subgroup: {number}
        </h2>
      </div>
      <div
        className="row people"
        style={{ paddingTop: "0px", paddingBottom: "5px" }}
      >
        <div className="col-md-6 col-lg-4 item">
          <div
            className="box"
            style={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            <img
              alt="img"
              className="rounded-circle"
              src={A.photoLine}
              style={{ width: "118px", height: "148px" }}
            />
            <h3
              className="name"
              style={{
                marginTop: "14px",
                fontWeight: 300,
                marginBottom: "2px",
                color: "#f3b00c"
              }}
            >
              {A.name}
            </h3>
            <p
              className="title"
              style={{ marginBottom: "2px", color: "#f3b00c" }}
            >
              {A.intro}
            </p>
            <div className="social" style={{ marginBottom: "5px" }}>
              <a
                href={
                  "https://api.whatsapp.com/send?phone=" +
                  A.waNumber +
                  "&text=Hi+5amier"
                }
              >
                <span
                  style={{
                    color: "rgb(13,13,13)",
                    marginLeft: "0px",
                    marginRight: "5px"
                  }}
                >
                  {A.waNumber}
                </span>
                <i
                  className="fa fa-whatsapp"
                  style={{ color: "var(--success)" }}
                />
              </a>
            </div>
            <div>
              <a
                className="btn btn-primary"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls={"a" + number}
                href={"#" + "a" + number}
                role="button"
                style={{
                  background: "rgb(255,148,23)",
                  borderWidth: "0px",
                  borderRadius: "7px",
                  marginBottom: "2px"
                }}
              >
                Toggle Info
              </a>
              <div className="collapse" id={"a" + number}>
                <div style={{ paddingTop: "15px", textAlign: "left" }}>
                  <span style={{ color: "#f3b00c" }}>
                    Scribble space to express yourself:&nbsp;
                  </span>
                  <p>{A.scribbleSpace}</p>
                  <span style={{ color: "#f3b00c" }}>
                    Contribution to know what you can offer to others in coming
                    21 days:
                  </span>
                  <p>{A.contribution}</p>
                  <span style={{ color: "#f3b00c" }}>From</span>
                  <p>{A.place}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* two */}
        <div className="col-md-6 col-lg-4 item">
          <div
            className="box"
            style={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            <img
              alt="img"
              className="rounded-circle"
              src={B.photoLine}
              style={{ width: "118px", height: "148px" }}
            />
            <h3
              className="name"
              style={{
                marginTop: "14px",
                fontWeight: 300,
                marginBottom: "2px",
                color: "#f3b00c"
              }}
            >
              {B.name}
            </h3>
            <p
              className="title"
              style={{ marginBottom: "2px", color: "#f3b00c" }}
            >
              {B.intro}
            </p>
            <div className="social" style={{ marginBottom: "5px" }}>
              <a
                href={
                  "https://api.whatsapp.com/send?phone=" +
                  B.waNumber +
                  "&text=Hi+5amier"
                }
              >
                <span
                  style={{
                    color: "rgb(13,13,13)",
                    marginLeft: "0px",
                    marginRight: "5px"
                  }}
                >
                  {B.waNumber}
                </span>
                <i
                  className="fa fa-whatsapp"
                  style={{ color: "var(--success)" }}
                />
              </a>
            </div>
            <div>
              <a
                className="btn btn-primary"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls={"b" + number}
                href={"#b" + number}
                role="button"
                style={{
                  background: "rgb(255,148,23)",
                  borderWidth: "0px",
                  borderRadius: "7px",
                  marginBottom: "2px"
                }}
              >
                Toggle Info
              </a>
              <div className="collapse" id={"b" + number}>
                <div style={{ paddingTop: "15px", textAlign: "left" }}>
                  <span style={{ color: "#f3b00c" }}>
                    Scribble space to express yourself:&nbsp;
                  </span>
                  <p>{B.scribbleSpace}</p>
                  <span style={{ color: "#f3b00c" }}>
                    Contribution to know what you can offer to others in coming
                    21 days:
                  </span>
                  <p>{B.contribution}</p>
                  <span style={{ color: "#f3b00c" }}>From</span>
                  <p>{B.place}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* three */}
        <div className="col-md-6 col-lg-4 item">
          <div
            className="box"
            style={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            <img
              alt="img"
              className="rounded-circle"
              src={C.photoLine}
              style={{ width: "118px", height: "148px" }}
            />
            <h3
              className="name"
              style={{
                marginTop: "14px",
                fontWeight: 300,
                marginBottom: "2px",
                color: "#f3b00c"
              }}
            >
              {C.name}
            </h3>
            <p
              className="title"
              style={{ marginBottom: "2px", color: "#f3b00c" }}
            >
              {C.intro}
            </p>
            <div className="social" style={{ marginBottom: "5px" }}>
              <a
                href={
                  "https://api.whatsapp.com/send?phone=" +
                  C.waNumber +
                  "&text=Hi+5amier"
                }
              >
                <span
                  style={{
                    color: "rgb(13,13,13)",
                    marginLeft: "0px",
                    marginRight: "5px"
                  }}
                >
                  {C.waNumber}
                </span>
                <i
                  className="fa fa-whatsapp"
                  style={{ color: "var(--success)" }}
                />
              </a>
            </div>
            <div>
              <a
                className="btn btn-primary"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls={"c" + number}
                href={"#c" + number}
                role="button"
                style={{
                  background: "rgb(255,148,23)",
                  borderWidth: "0px",
                  borderRadius: "7px",
                  marginBottom: "2px"
                }}
              >
                Toggle Info
              </a>
              <div className="collapse" id={"c" + number}>
                <div style={{ paddingTop: "15px", textAlign: "left" }}>
                  <span style={{ color: "#f3b00c" }}>
                    Scribble space to express yourself:&nbsp;
                  </span>
                  <p>{C.scribbleSpace}</p>
                  <span style={{ color: "#f3b00c" }}>
                    Contribution to know what you can offer to others in coming
                    21 days:
                  </span>
                  <p>{C.contribution}</p>
                  <span style={{ color: "#f3b00c" }}>From</span>
                  <p>{C.place}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
